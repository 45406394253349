export const language = {
  it_IT: {
    volantino: "Volantino",
    prodotti: "Prodotti",
    pagine: "Pagine",
    pdf: "PDF",
    catalogo: "Catalogo",
    schermo_intero: "Schermo Intero",
    condividi_volantino: "Condividi Volantino",
    prodotto: "Prodotto",
    scegli_una_categoria: "Scegli una categoria",
    scegli_una_sottocategoria: "Scegli una sottocategoria",
    reset: "Reset",
    aggiungi_alla_lista: "Aggiungi alla lista",
    aggiungi: "Aggiungi",
    mostra_altri_prodotti: "Carica altri prodotti",
    nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti:
      "Nessun Prodotto trovato per i parametri di ricerca inseriti",
    dettaglio: "Dettaglio",
    indietro: "Indietro",
    chiudi: "chiudi",
    scegli: "Scegli",
    prodotto_aggiunto: "Prodotto Aggiunto",
    controlla_la_tua_lista_della_spesa: "Controlla la tua lista della spesa",
    lista_della_spesa: "Lista della spesa",
    la_tua_lista_della_spesa: "La tua lista della spesa",
    elimina_dalla_lista: "Elimina dalla lista",
    condividi_lista: "Condividi lista",
    condividi_con: "Condividi con",
    totale: "Totale",
    svuota: "Svuota",
    inserisci_un_prodotto_non_presente: "Inserisci un prodotto non presente",
    nome_prodotto: "Nome prodotto",
    prezzo_stimato: "Prezzo stimato",
    cerca_un_prodotto: "Cerca prodotto",
    link_copiato_negli_appunti: "Link Copiato Negli appunti",
    curiosita: "Curiosità",
    video: "Video",
    ricetta: "Ricetta",
    ingredienti: "Ingredienti",
    procedimento: "Procedimento",
    indice: "Indice",
    menu: "Menù",
    video_tutorial: "Video Tutorial",
    condividi: "Condividi",
    filtra_prodotti: "Filtra prodotti",
    mostra: "Mostra",
    risultati: "risultati",
    sto_caricando: "Sto caricando",
    secondi: "secondi",
    sfoglia_volantino_tra: "Sfoglia il volantino tra",
    reset_filtri: "Reset filtri",
    descrizione: "Descrizione",
    carosello: "Carosello",
    per_quantita: "per quantità",
    offerta: "Offerta",
    scopri_il_prezzo: "Scopri il prezzo",
    scopri: "Scopri",
    attendi_scopri_prezzo: "Attendi qualche istante per scoprire il prezzo di",
    caratteristiche: "Caratteristiche",
    il_tuo_carrello: "Il tuo carrello",
    stiamo_generando_lista_spesa: "Stiamo generando la tua lista della spesa",
    visualizza_lista_spesa: "Visualizza la tua lista della spesa",
    elemento_copiato: "Elemento copiato",
    errore_nella_copia: "Errore nella copia",
    link_non_copiato: "Link non copiato",
    torna_al_volantino: "Torna al volantino",
    salva_come_immagine: "Salva come immagine",
    sei_sicuro_svuota_carrello: "Sei sicuro di voler svuotare il tuo carrello",
    annulla: "Annulla",
    conferma: "Conferma"
  },

  en_EN: {
    volantino: "Flyer",
    prodotti: "Products",
    catalogo: "Catalogue",
    pagine: "Pages",
    pdf: "PDF",
    schermo_intero: "Fullscreen",
    condividi_volantino: "Share Flyer",
    prodotto: "Product",
    scegli_una_categoria: "Choose a category",
    scegli_una_sottocategoria: "Choose a subcategory",
    reset: "Reset",
    aggiungi_alla_lista: "Add to list",
    aggiungi: "Aggiungi",
    mostra_altri_prodotti: "Show more products",
    nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti:
      "No Product found for search parameters",
    dettaglio: "Detail",
    indietro: "Back",
    chiudi: "Close",
    scegli: "Choose",
    prodotto_aggiunto: "Product Added",
    controlla_la_tua_lista_della_spesa: "Check your shopping list",
    lista_della_spesa: "Shopping list",
    la_tua_lista_della_spesa: "Your shopping list",
    elimina_dalla_lista: "Delete from list",
    condividi_lista: "Share list",
    condividi_con: "Share with",
    totale: "Total",
    svuota: "Clear",
    inserisci_un_prodotto_non_presente: "Enter a non-existing product",
    nome_prodotto: "Product Name",
    prezzo_stimato: "Estimated Price",
    cerca_un_prodotto: "Search a Product",
    link_copiato_negli_appunti: "Link Copied To the clipboard",
    curiosita: "Curiosity",
    video: "Video",
    ricetta: "Recipe",
    ingredienti: "Ingredients",
    procedimento: "Proceedings",
    indice: "Index",
    menu: "Menù",
    video_tutorial: "Video Tutorial",
    condividi: "Share",
    filtra_prodotti: "Filter products",
    mostra: "Show",
    risultati: "results",
    sto_caricando: "loading",
    secondi: "seconds",
    sfoglia_volantino_tra: "Browse the flyer in",
    reset_filtri: "Reset filters",
    descrizione: "Description",
    carosello: "Carousel",
    per_quantita: "for quantity",
    offerta: "Offer",
    scopri_il_prezzo: "Show price",
    scopri: "Show",
    attendi_scopri_prezzo: "Wait a few moments to see the price of",
    caratteristiche: "Specs",
    il_tuo_carrello: "Your Shopping Cart",
    stiamo_generando_lista_spesa: "Generating your shopping list",
    visualizza_lista_spesa: "Show your shopping list",
    elemento_copiato: "Item copied",
    errore_nella_copia: "Copy error",
    link_non_copiato: "Link not copied",
    torna_al_volantino: "Back to flyer",
    salva_come_immagine: "Save as image",
    sei_sicuro_svuota_carrello: "Are you sure to clear your cart",
    annulla: "Cancel",
    conferma: "Confirm"
  }
};
